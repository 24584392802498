<template>
  <v-container class="card-check pa-0 pa-sm-3 mt-4 mt-sm-0" fluid>
    <CategoryTitle :category="category" />

    <v-form
      ref="registerform"
      v-model="isCardFormValid"
      @submit.prevent.stop="handleRegister"
    >
      <div class="choose-card px-3 mb-6 mt-4 grey lighten-2 rounded py-3">
        {{ $t("register.user.switch-text") }}
        <v-btn
          color="primary"
          class="mx-4"
          min-width="113"
          :outlined="!cardFieldEnabled"
          depressed
          @click="toggleCardFieldEnabled(true)"
          >{{ $t("common.yes") }}</v-btn
        >
        <v-btn
          color="primary"
          class="mx-4"
          min-width="113"
          :outlined="cardFieldEnabled"
          depressed
          @click="toggleCardFieldEnabled(false)"
          >{{ $t("common.no") }}</v-btn
        >
      </div>

      <v-row no-gutters class="grey lighten-2 rounded pt-3">
        <!-- if already has fidelity -> slot to insert fidelity number -->
        <v-col cols="12" sm="6" v-if="cardFieldEnabled">
          <v-text-field
            v-model="registerData.fidelityCard"
            :rules="fidelityCardRules"
            :label="`${$t('register.user.fidelityCardNumber')}`"
            type="tel"
            required
            outlined
            flat
            filled
            dense
            v-mask="'#############'"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" v-else>
          <v-checkbox
            class="mb-6 mt-0"
            height="40"
            hide-details
            required
            v-model="wantFidelity"
          >
            <template v-slot:label>
              {{ $t("register.user.primaCardCheckbox") }}
            </template>
          </v-checkbox>
        </v-col>

        <v-col cols="12" sm="6" v-if="cardFieldEnabled || wantFidelity">
          <!-- data di nascita -->
          <v-menu
            ref="birthDateMenu"
            v-model="birthDateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <!-- v-mask="'##/##/####'" -->
              <v-text-field
                v-model="birthDate"
                :label="$t('register.user.birthDate')"
                :id="'id_' + Math.random()"
                required
                readonly
                outlined
                flat
                filled
                dense
                v-bind="attrs"
                v-on="on"
              >
                <v-icon v-bind="attrs" v-on="on" slot="append">
                  $editcalendar
                </v-icon>
              </v-text-field>
            </template>
            <v-date-picker
              locale="it-IT"
              ref="picker"
              :active-picker.sync="activePicker"
              v-model="registerData.person.birthDate"
              :id="'id_' + Math.random()"
              min="1910-01-01"
              :max="this.maxDate.format('YYYY-MM-DD')"
              first-day-of-week="1"
              type="date"
              no-title
              @input="onBirthdateInput"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <span v-if="wantFidelity" class="mx-3 mb-7">
          <v-icon class="mr-3">$info</v-icon
          >{{ $t("register.user.primaCardInfo") }}
        </span>

        <div v-if="!cardFieldEnabled && wantFidelity" class="d-flex px-3 mb-5">
          <v-checkbox
            v-if="isOver65"
            class="mb-6 mt-0"
            height="40"
            hide-details
            required
            v-model="wantFidelityOver65"
          >
            <template v-slot:label>
              {{ $t("register.user.primaCard65Checkbox") }}
            </template>
          </v-checkbox>
          <span>{{ $t("register.user.primaCard65CheckboxLabel") }}</span>
        </div>
      </v-row>

      <v-row no-gutters class="grey lighten-2 rounded">
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="registerData.email"
            :rules="emailRules"
            :label="`${$t('register.user.email')}`"
            required
            outlined
            flat
            filled
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            :rules="emailConfirmRules"
            :label="`${$t('register.user.emailConfirm')}`"
            required
            outlined
            flat
            filled
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <!-- password -->
          <v-text-field
            v-model="registerData.password"
            :append-icon="showPassword ? '$eye' : '$eyeOff'"
            :type="showPassword ? 'text' : 'password'"
            :rules="passwordRules"
            @click:append="toggleShowPassword"
            :label="`${$t('register.user.password')}`"
            required
            outlined
            flat
            filled
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <!-- conferma password -->
          <v-text-field
            :append-icon="showPassword ? '$eye' : '$eyeOff'"
            :type="showPassword ? 'text' : 'password'"
            :rules="passwordConfirmRules"
            @click:append="toggleShowPassword"
            :label="`${$t('register.user.passwordConfirm')}`"
            required
            filled
            outlined
            flat
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6">
          <!-- nome -->
          <v-text-field
            v-model="registerData.person.firstName"
            :rules="[requiredRules(), isName()]"
            :label="`${$t('register.user.firstName')}`"
            :maxlength="20"
            required
            filled
            outlined
            flat
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6">
          <!-- cognome -->
          <v-text-field
            v-model="registerData.person.lastName"
            :rules="[requiredRules(), isName()]"
            :label="`${$t('register.user.lastName')}`"
            :maxlength="20"
            filled
            dense
            outlined
            flat
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field
            v-model="registerData.phone"
            :rules="phoneRules"
            :label="$t('register.user.phone')"
            filled
            dense
            outlined
            flat
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6">
          <!-- punto vendita di riferimento -->
          <v-select
            v-model="registerData.defaultStoreAddress.addressId"
            :items="warehouses"
            item-text="address.addressName"
            item-value="address.addressId"
            :label="`${$t('register.user.favouriteWh')}`"
            :placeholder="$t('register.user.favouriteWh')"
            filled
            dense
            outlined
            flat
          ></v-select>
        </v-col>

        <span class="mx-3 mb-7">
          <v-icon class="mr-3">$info</v-icon
          >{{ $t("register.user.emailInfoText") }}
        </span>
        <!-- <v-alert outlined type="info" class="mx-3 mb-7">
            {{ $t("register.user.emailInfoText") }}
          </v-alert> -->

        <template v-if="wantFidelity && !cardFieldEnabled">
          <h4 class="px-3 w-100 mb-3">
            {{ $t("register.user.addressTitle") }}
          </h4>
          <v-col cols="12" sm="3">
            <!-- PROVINCE -->
            <v-combobox
              :label="$t('register.user.province')"
              v-model="registerData.billingAddress.province"
              :rules="[requiredRules()]"
              :items="getProvincesList(registerData.billingAddress.province)"
              append-icon=""
              height="40"
              outlined
              flat
              filled
              dense
              return-object
              item-text="name"
              item-value="name"
              @change="changeProvince"
              autocomplete="chrome-off"
            ></v-combobox>
          </v-col>
          <v-col cols="12" sm="6">
            <!-- CITY -->
            <v-combobox
              :label="$t('register.user.city')"
              v-model="registerData.billingAddress.city"
              :rules="[requiredRules()]"
              :items="getCitiesList(registerData.billingAddress.city)"
              append-icon=""
              height="40"
              outlined
              flat
              filled
              dense
              item-text="name"
              item-value="name"
              @change="changeCity"
              autocomplete="chrome-off"
            ></v-combobox>
          </v-col>
          <v-col cols="12" sm="3">
            <!-- POSTAL CODE -->
            <v-combobox
              :label="$t('register.user.postalCode')"
              v-model="registerData.billingAddress.postalcode"
              :rules="[requiredRules()]"
              :items="getCapsList(registerData.billingAddress.postalcode)"
              append-icon=""
              height="40"
              outlined
              flat
              filled
              dense
              item-text="name"
              item-value="name"
              @change="changeCap"
              autocomplete="chrome-off"
            ></v-combobox>
          </v-col>
          <v-col cols="12" sm="6">
            <!-- ADDRESS1 -->
            <v-text-field
              :label="$t('register.user.address')"
              v-model="registerData.billingAddress.address1"
              :rules="[requiredRules()]"
              append-icon=""
              height="40"
              outlined
              flat
              filled
              dense
            ></v-text-field>
            <!-- <v-combobox
              :label="$t('register.user.address')"
              v-model="registerData.billingAddress.address1"
              :rules="[requiredRules()]"
              :items="getAddressesList(registerData.billingAddress.address1)"
              append-icon=""
              height="40"
              outlined
              flat
              filled
              dense
              item-text="name"
              item-value="name"
              @change="changeAddress1"
              autocomplete="chrome-off"
            ></v-combobox> -->
          </v-col>
          <v-col cols="12" sm="3">
            <!-- ADDRESS NUMBER -->
            <v-text-field
              :label="$t('register.user.addressNumber')"
              v-model="registerData.billingAddress.addressNumber"
              :rules="[requiredRules()]"
              outlined
              flat
              filled
              dense
              @change="validate"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="3">
            <!-- INTERNO, apartmentNumber -->
            <v-text-field
              :label="$t('register.user.apartmentNumber')"
              v-model="registerData.billingAddress.apartmentNumber"
              outlined
              flat
              filled
              dense
              @change="validate"
            ></v-text-field>
          </v-col>
        </template>
        <span class="text-body-2 px-3 pb-3">{{
          $t("register.user.obbligatoryField")
        }}</span>
      </v-row>

      <div
        class="agreement-container px-3 mt-7 grey lighten-2 rounded py-3"
        v-if="cardFieldEnabled || wantFidelity"
      >
        <h4 class="agreement-title mb-3">
          {{ $t("register.user.agreementTitle") }}
        </h4>
        <router-link
          class="privacy-link"
          :to="$t('register.user.privacyLink')"
          >{{ $t("register.user.privacyText") }}</router-link
        >
        <span v-if="$te('register.user.primaCardAgreementText')">
          e il
          <a
            class="prima-card-link"
            @click.prevent.stop="
              handleReceiptDownload(
                $t('register.user.primaCardAgreementLink'),
                $t('register.user.primaCardAgreementText')
              )
            "
            >{{ $t("register.user.primaCardAgreementText") }}</a
          ></span
        >
        <span class="d-block agreement mt-2">
          {{ $t("register.user.agreement") }}
        </span>
        <span class="d-block profilation-title font-weight-bold mt-2">
          {{ $t("register.user.profilationTitle") }}
        </span>
        <span class="d-block profilation-descr">
          {{ $t("register.user.profilationDescr") }}
        </span>
        <v-radio-group
          dense
          :rules="[requiredRules()]"
          v-model="disclaimer1"
          row
        >
          <v-radio :value="'true'">
            <template v-slot:label>
              <span class="disclaimer-label disclaimer-radio-label">{{
                $t("common.yes")
              }}</span>
            </template>
          </v-radio>
          <v-radio :value="'false'">
            <template v-slot:label>
              <span class="disclaimer-label disclaimer-radio-label">{{
                $t("common.no")
              }}</span>
            </template>
          </v-radio>
        </v-radio-group>

        <span class="d-block marketing-title font-weight-bold mt-2">
          {{ $t("register.user.marketingTitle") }}
        </span>
        <span class="d-block marketing-descr">
          {{ $t("register.user.marketingDescr") }}
        </span>
        <v-radio-group
          :rules="[requiredRules()]"
          dense
          v-model="disclaimer2"
          row
        >
          <v-radio :value="'true'">
            <template v-slot:label>
              <span class="disclaimer-label disclaimer-radio-label">{{
                $t("common.yes")
              }}</span>
            </template>
          </v-radio>
          <v-radio :value="'false'">
            <template v-slot:label>
              <span class="disclaimer-label disclaimer-radio-label">{{
                $t("common.no")
              }}</span>
            </template>
          </v-radio>
        </v-radio-group>
      </div>

      <div class="d-flex justify-end mt-5">
        <v-btn
          :loading="loading"
          type="submit"
          class="register-user-btn"
          color="primary"
          min-width="250"
          large
          depressed
        >
          {{ $t("register.user.registerBtn") }}
        </v-btn>
      </div>
      <recaptcha-disclaimer />
    </v-form>

    <ResponseMessage :response="response" class="mt-4" />
  </v-container>
</template>
<style scoped lang="scss">
.card-check {
  div[class*="col-"] {
    padding: 0 12px;
  }
  a {
    color: var(--v-default-base);
  }
}
.prima-card-link {
  text-decoration: underline;
}
</style>
<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import ResponseMessage from "@/components/ResponseMessage";

import categoryMixins from "~/mixins/category";
import { setPersonInfo } from "~/service/ebsn";
import BaskoCustomService from "@/service/baskoCustomService";
import AddressService from "~/service/addressService";
import RecaptchaDisclaimer from "~/components/RecaptchaDisclaimer.vue";
import AnalyticsService from "~/service/analyticsService";
import downloadDocumentMixin from "~/mixins/downloadDocument";

import { mapActions } from "vuex";

import {
  requiredValue,
  isEmail,
  isNumber,
  isMobilePhone,
  isLength,
  isPasswordMinimumLength,
  genericPeopleName
} from "~/validator/validationRules";
import { mask } from "vue-the-mask";

export default {
  name: "RegistrationUser",
  directives: { mask },
  mixins: [categoryMixins, downloadDocumentMixin],
  components: { CategoryTitle, ResponseMessage, RecaptchaDisclaimer },
  data() {
    return {
      loading: false,
      wantFidelity: false,
      wantFidelityOver65: true,
      cardFieldEnabled: true,
      isCardFormValid: false,
      showPassword: false,
      birthDateMenu: false,
      activePicker: null,
      selectedCityId: null,
      citiesUnderCap: [],
      provinceList: [],
      cityList: [],
      capList: [],
      addressList: [],
      warehouses: [],
      disclaimer1: null,
      disclaimer2: null,
      requiredRules: requiredValue,
      requiredValue: requiredValue,
      phoneRules: [requiredValue(), isMobilePhone()],
      emailRules: [requiredValue("Digitare l'e-mail"), isEmail()],
      isEmail: isEmail,
      isNumber: isNumber,
      isName: genericPeopleName,
      fidelityCardRules: [
        requiredValue(),
        isLength(13, "La PrimaCard è lunga 13 numeri")
      ],
      passwordRules: [
        requiredValue("Digitare la password"),
        isPasswordMinimumLength()
      ],
      emailConfirmRules: [
        v => !!v || "Confermare l'e-mail",
        v => v === this.registerData.email || "Le e-mail non coincidono"
      ],
      passwordConfirmRules: [
        v => !!v || "Confermare la password",
        v => v === this.registerData.password || "Le password non coincidono"
      ],
      registerData: {
        person: {},
        billingAddress: {},
        defaultStoreAddress: {},
        country: {
          itemId: "1",
          iso: "IT",
          name: "Italia"
        }
      },
      checked: false,
      response: {},
      country: {
        itemId: "1",
        iso: "IT",
        name: "Italia"
      }
    };
  },
  computed: {
    birthDate: {
      get: function() {
        if (this.registerData.person.birthDate) {
          return this.$dayjs(this.registerData.person.birthDate).format(
            "DD/MM/YYYY"
          );
        } else {
          return null;
        }
      },
      set: function(value) {
        this.registerData.person.birthDate = this.$dayjs(value).format(
          "DD/MM/YYYY"
        );
      }
    },
    isOver65() {
      if (this.cardFieldEnabled || this.wantFidelity) {
        let date = this.registerData.person.birthDate;
        if (date) {
          let birthDatePlus65Years = this.$dayjs(date).add(65, "year");
          return birthDatePlus65Years.isBefore(this.$dayjs()) ? true : false;
        }
      }
      return false;
    },
    primaCardAction() {
      // DONT_REQUEST: 0,
      // ASSOCIATE: 1,
      // REQUEST_PHYSICAL: 2,
      // REQUEST_VIRTUAL: 3,
      // REQUEST_65_PHYSICAL: 4,
      if (this.cardFieldEnabled) {
        return 1;
      } else if (this.wantFidelity) {
        if (this.wantFidelityOver65 && this.isOver65) {
          setPersonInfo(this.registerData.person, "4", "1");
          return 4;
        } else {
          return 3;
        }
      }
      return 0;
    },
    maxDate() {
      let eighteenYearsAgo = this.$dayjs().subtract(18, "year");
      return eighteenYearsAgo;
    }
  },
  methods: {
    ...mapActions({
      getCart: "cart/getCart"
    }),
    toggleCardFieldEnabled(newVal) {
      this.cardFieldEnabled = newVal;
      this.response = {};
    },
    async handleRegister() {
      try {
        this.loading = true;
        if (this.$refs.registerform.validate()) {
          this.registerData.person.personInfos = [
            {
              personInfoTypeId: 1,
              value:
                this.disclaimer1 && (this.wantFidelity || this.cardFieldEnabled)
                  ? this.disclaimer1
                  : false
            },
            {
              personInfoTypeId: 2,
              value:
                this.disclaimer2 && (this.wantFidelity || this.cardFieldEnabled)
                  ? this.disclaimer2
                  : false
            },
            {
              personInfoTypeId: 3,
              value:
                this.disclaimer2 && (this.wantFidelity || this.cardFieldEnabled)
                  ? this.disclaimer2
                  : false
            },
            {
              personInfoTypeId: 5,
              value: false
            }
          ];

          let response = await BaskoCustomService.registrationPrimaUser(
            this.registerData,
            this.primaCardAction
          );
          this.loading = false;
          if (response.response && response.response.status === 0) {
            let method = null;
            if (response.data.user.fidelityCard == "") {
              method = "Registration no card";
            } else {
              method = "Registration card";
            }
            AnalyticsService.signUp(method, response.data.user.userId);
            this.getCart();
            this.$router.push({
              path: "/registration-completed",
              name: "RegistrationCompleted",
              params: {
                email: this.registerData.login
              }
            });
          } else {
            this.response = response.response;
          }
        } else {
          this.response = {
            errors: [
              {
                error: this.$t("common.incorrectFormMessage")
              }
            ]
          };
        }
      } catch (response) {
        if (typeof response === "undefined") {
          this.response = {
            errors: [{ error: this.$t("errors.errorCompletingRegistration") }]
          };
        } else {
          this.response = response;
        }
      } finally {
        this.loading = false;
      }
    },
    toggleShowPassword(e) {
      e.preventDefault();
      this.showPassword = !this.showPassword;
    },

    // address
    changeProvince(province) {
      this.registerData.billingAddress.province = province.name;
      this.registerData.billingAddress.city = null;
      this.registerData.billingAddress.postalcode = null;
      this.registerData.billingAddress.address1 = null;
      this.cityList = [];
      this.capList = [];
      // this.addressList = [];
      this.selectedCityId = null;
      this.getCityList(province.itemId);
    },
    changeCity(city) {
      this.registerData.billingAddress.city = city.name;
      this.registerData.billingAddress.postalcode = null;
      this.registerData.billingAddress.address1 = null;
      this.capList = [];
      // this.addressList = [];
      this.selectedCityId = city.itemId;
      this.getCapList(city.itemId);
    },
    changeCap(cap) {
      this.registerData.billingAddress.postalcode = cap.name;
      this.registerData.billingAddress.address1 = null;
      // this.addressList = [];
      // this.getAddressList(cap.itemId);
    },
    // changeAddress1(address1) {
    //   this.registerData.billingAddress.address1 = address1.name;
    // },
    // async getAddressList(capId) {
    //   this.addressList = await BaskoCustomService.streetList(
    //     this.selectedCityId,
    //     capId
    //   );
    // },
    async getCityList(provinceId) {
      this.cityList = await BaskoCustomService.cityList(provinceId);
    },
    async getCapList(capId) {
      this.capList = await BaskoCustomService.postcodeList(capId);
    },
    getCitiesList(text) {
      let _this = this;
      var filteredCities = [];
      let insertedText = text?.name || text;
      if (insertedText) {
        var formattedText = insertedText.toLowerCase();
        for (var i = 0; i < _this.cityList.length; i++) {
          var formattedCityName = _this.cityList[i].name.toLowerCase();
          if (
            (formattedText.length == 1 &&
              formattedCityName[0] == formattedText) ||
            (formattedText.length > 1 &&
              formattedCityName.includes(formattedText))
          ) {
            filteredCities.push(_this.cityList[i]);
          }
        }
      } else {
        filteredCities = _this.cityList;
      }
      return filteredCities;
    },
    // getAddressesList(text) {
    //   let _this = this;
    //   var filteredAddresses = [];
    //   let insertedText = text?.name || text;
    //   if (insertedText) {
    //     var formattedText = insertedText.toLowerCase();
    //     for (var i = 0; i < _this.addressList.length; i++) {
    //       var formattedAddressName = _this.addressList[i].name.toLowerCase();
    //       if (
    //         (formattedText.length == 1 &&
    //           formattedAddressName[0] == formattedText) ||
    //         (formattedText.length > 1 &&
    //           formattedAddressName.includes(formattedText))
    //       ) {
    //         filteredAddresses.push(_this.addressList[i]);
    //       }
    //     }
    //   } else {
    //     filteredAddresses = _this.addressList;
    //   }
    //   return filteredAddresses;
    // },
    getCapsList(text) {
      let _this = this;
      var filteredCaps = [];
      let insertedText = text?.name || text;
      if (insertedText) {
        var formattedText = insertedText.toLowerCase();
        for (var i = 0; i < _this.capList.length; i++) {
          var formattedCapName = _this.capList[i].name.toLowerCase();
          if (
            (formattedText.length == 1 &&
              formattedCapName[0] == formattedText) ||
            (formattedText.length > 1 &&
              formattedCapName.includes(formattedText))
          ) {
            filteredCaps.push(_this.capList[i]);
          }
        }
      } else {
        filteredCaps = _this.capList;
      }
      return filteredCaps;
    },
    getProvincesList(text) {
      let _this = this;
      var filteredProvinces = [];
      let insertedText = text?.name || text;
      if (insertedText) {
        var formattedText = insertedText.toLowerCase();
        for (var i = 0; i < _this.provinceList.length; i++) {
          var formattedProvinceName = _this.provinceList[i].name.toLowerCase();
          if (
            (formattedText.length == 1 &&
              formattedProvinceName[0] == formattedText) ||
            (formattedText.length > 1 &&
              formattedProvinceName.includes(formattedText))
          ) {
            filteredProvinces.push(_this.provinceList[i]);
          }
        }
      } else {
        filteredProvinces = _this.provinceList;
      }
      return filteredProvinces;
    },
    getProvinces(country, homeDeliveryOnly) {
      let _this = this;
      if (_this.provinceList.length < 1) {
        BaskoCustomService.provinceList(country.itemId, homeDeliveryOnly).then(
          function(results) {
            _this.provinceList = results;
          }
        );
      }
    },
    async getWarehouses() {
      let res = await AddressService.findWarehouse();
      if (res && res.warehouses) {
        this.warehouses = res.warehouses;
      }
    },
    validate() {
      let _this = this;
      setTimeout(function() {
        if (_this.$refs.registerform) {
          _this.valid = _this.$refs.registerform.validate();
          if (_this.isProfileForm) {
            _this.$emit("validate", _this.valid);
          }
        }
      }, 200);
    },
    onBirthdateInput() {
      this.birthDateMenu = false;
      this.validate();
    }
  },
  async mounted() {
    await this.getProvinces(this.country, false);
    await this.getWarehouses();
  },
  watch: {
    birthDateMenu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    }
  }
};
</script>
